import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { AuthenticationService } from '../services/authentication.service';
import { window } from 'rxjs-compat/operator/window';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import swal from 'sweetalert';
import { AppStateService } from '../services/app-state.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  userName: any;
  password: any;

  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private _appStateService: AppStateService,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  get f() { return this.loginForm.controls; }

  onSubmit(data: any) {

    if (this.loginForm.invalid) {
      return;
    }
    console.log('Data passed from screen ', this.loginForm);
    this.spinnerService.show();   
    this.authService.azureAdAuthentication(this.loginForm.value).
    subscribe(
      resp => {
          console.log('This is the response from serivce ', resp);
          const responseObj: any = resp;
          this.spinnerService.hide();
          console.log("responseObj",responseObj.message);
          if (responseObj.message !== undefined && responseObj.status==="success") {

            this._appStateService.username = responseObj.message.bot_user.split('@')[0];
            this.router.navigate(['/admin/chat-box'], { queryParams: { data: btoa(JSON.stringify(responseObj.message)) }});
          } else {
            this.spinnerService.hide();
            swal({
              text: 'Please enter valid credentials..!!',
              icon: 'error',
              className: 'swal-wide'
            });
          }
      });

/*
     let result =  btoa(JSON.stringify({
      bot_refresh_token: "AZ",
      bot_user: "bot.test@bott2.onmicrosoft.com",
      bot_access_token_1: "eyJ",
      bot_access_token_2: "b24",
      bot_access_token_3: "WRCe",
      bot_expires_on: "1536927787",
      directlineSecrect: "MCRqUv-m-pk.cwA.X5I.WO-AHzQFuMUM4vnbWWovBM_AKI8gmghNEskGUziG5dE",
      jwtToken:"sdxfcgvhbjnkmlsdfvsdhikytasfkjsdfykjsdfhkjsdgf"
    }));

    this.router.navigate(['/admin/home'], {queryParams : { data: result }});
*/
  }

  ngOnDestroy() {
    this.spinnerService.hide();
  }

}


