import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppStateService } from '../services/app-state.service';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs-compat/operator/debounceTime';
import { map, startWith, tap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs-compat/operator/distinctUntilChanged';
import { I18nService } from '../services/i18n.service';
import { AuthenticationService } from '../services/authentication.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SharedService } from '../services/sharedService';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  public i18nTexts: any;

  subscribeToWinResize(){
    this._appStateService.resizeWidth$.subscribe(
      data => {
        console.log("** Win resized from Nav: ", data);
      }
    );
  }

  changeLanguage(){
    this._i18nService.setLocale(1);
  }


  onLogoutClick(){
   // alert("logout clicked");
    
    this._sharedService.onLogoutClick()
    this.authService.logoutUser().subscribe(
      resp => {
        //console.log("reponse onLogout:",resp)
        let sessionToken = localStorage.getItem('csd-x-auth-token');
        localStorage.setItem(sessionToken, "chat_ui_visited");
      //   alert("You have Logged out Sucessfully!");
 

       this.router.navigate(['/logout']);
       //
      },
      error => {
        //console.log("error onLogout",error)
      });
  }

  constructor(
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
    private router: Router,
    private authService: AuthenticationService,
    public _sharedService: SharedService
  ) {
    console.log(` ** my name => ${this.constructor.name}`);
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(this.constructor.name);
  }


  ngOnInit() {
    this.subscribeToWinResize();
    console.log("inside ngOninit : logout handler");
  }


  ngOnDestroy() {
    // Un-subscribe ??
  }

}
