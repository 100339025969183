import { isUndefined } from "util";
import { DecoratedText } from "./DecoratedText";

export class ChatMessageModel {
    // public msgId: string;
    public author: MsgAuthor;
    public msgBody: any;
    public msgType: MsgType;
    public timestamp: string;
    public isAvatarShown: boolean = true;
    public isExpanded: boolean = false;

    private _isWaitingShown: boolean = false;
    private _isDeliveryShown: boolean = false;
    private _isAckPending: boolean;
    private _shouldResend: boolean = false;


    get isAckPending() {
        return this._isAckPending;
    }

    get isWaitingShown() {
        return this._isWaitingShown;
    }

    get isDeliveryShown() {
        return this._isDeliveryShown;
    }

    get shouldResend() {
        return this._shouldResend;
    }


    formatTime(time: Date) {
        let hours: number = time.getHours();
        let ampm: string = (hours >= 12) ? 'pm' : 'am';
        let minutes: number = time.getMinutes();
        let fMins: string = (minutes < 10) ? `0${minutes}` : minutes.toString();

        // the hour '0' should be '12'
        hours = (hours % 12) ? (hours % 12) : 12;

        return `${hours}:${fMins} ${ampm}`;
    }


    private setAckPendingStatus(status: boolean) {
        this._isAckPending = status;
        this._isWaitingShown = status;
    }


    acknowledgeMsg() {
        this.setAckPendingStatus(false);
        this._shouldResend = false;

        // Show the delivery msg and hide it after 10s.
        this._isDeliveryShown = true;
        setTimeout(() => this._isDeliveryShown = false, 10000);
    }


    flagForResend() {
        if ((this.author == MsgAuthor.User)) {
            this._shouldResend = true;
            this._isWaitingShown = false;
        }
    }


    constructor(sender: MsgAuthor, body: any, type: MsgType, time: Date, isAckPending?: boolean) {
        // TODO: provide the arr-idx to the platform and make sure it reverts back.
        // Will be used in the functionality of brightening the "POST-success" user message.
        // this.msgId = id;
        // this.arrIdx = id;

        this.author = sender;
        this.msgBody = body;
        this.msgType = type;
        this.timestamp = this.formatTime(time);

        // if(sender == MsgAuthor.Bot && (type == MsgType.TextOnly || type == MsgType.ChoiceCard || type == MsgType.YesNoCard)){
        if(sender == MsgAuthor.Bot && (type == MsgType.TextOnly)){
            this.msgBody = new DecoratedText(body);
            console.log(" ** Decorated Text: ", this.msgBody);
        }

        if (!isUndefined(isAckPending)) {
            this.setAckPendingStatus(isAckPending);
        } else {
            this.setAckPendingStatus(sender == MsgAuthor.User);
        }
    }
}


export enum MsgType {
    TextOnly,
    TextFormatted,
    YesNoCard,
    ChoiceCard,
    ThumbsCard,
    NewTicketCard,
    StatusCardCollection,
    InfoCardCollection,
    ActionCard,
    VideoCard,
    Calender,
    OutageCard,
    FormCard,
    NewTicketCardAD,
    FaqCard,
    Podetails,
    getQtyCard0, 
    AGREEMENT_CARD
}


export enum MsgAuthor {
    Bot,
    User
}