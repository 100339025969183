import { Component, OnInit } from '@angular/core';
import { SharedService } from '../services/sharedService';
import { AppStateService } from '../services/app-state.service';
import { I18nService } from '../services/i18n.service';

@Component({
  selector: 'app-sidebar2',
  templateUrl: './sidebar2.component.html',
  styleUrls: ['./sidebar2.component.css']
})
export class Sidebar2Component implements OnInit {

  public isSidebarShown: boolean = false;
  public i18nTexts: any;

  hideMobileSidebar(){
    if(this._appStateService.currWidth <= this._appStateService.mobileWidth){
      this.isSidebarShown = false;
    }
  }


  showMobileSidebar(){
    if(this._appStateService.currWidth <= this._appStateService.mobileWidth){
      this.isSidebarShown = true;
    }
  }


  stopPropagation(event: Event){
    event.stopPropagation();
  }

  // resetConv(){
  //   console.log("reset conversation");
  //   this._sharedService.sendClickEvent();
  // }
  

  constructor(
    private _sharedService: SharedService,
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
  ) {
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(this.constructor.name);
  }

  ngOnInit() {
  }

  userClicked(value :string){
    this._sharedService.userClickedfromLeftmenu(value);
    this.hideMobileSidebar();
  }
}
