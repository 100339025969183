import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-dropdown-card',
  templateUrl: './dropdown-card.component.html',
  styleUrls: ['./dropdown-card.component.css']
})
export class DropdownCardComponent implements OnInit {

  @Input() formStructure: any;
  @Output() formValue = new EventEmitter();
  public DdSelect : any
  public ondisable : boolean = false
  public showClass : boolean = false
  constructor() { 
    this.ondisable = false
    this.showClass = false
  }

  ngOnInit() {
    this.ondisable = false
    this.showClass = false
    this.parseAndInit(this.formStructure)

  } 
  public formRows: Array<FormCardIpRow> = [];
  //   public choices: Array<string> = [];
    public selectedOption: number; // will store the idx of the selected choice
    public shouldSendAsValue: boolean = false;
    public optioSelected: any = ""
  
    parseAndInit(rawAdCard) {
      this.ondisable = false
      const cardBody: Array<any> = rawAdCard.content.body;
      let ipRowLabel: string, ipControlData: any, ipRowInfo: string,
        ipControlType: any, ipControlName: string;
  
      // Loop through the object and parse into appropriate structures.
      for (let i in cardBody) {
        const item = cardBody[i];
  
        switch (item.type) {
          case "TextBlock":
            // Label or Info
            if (item.size && item.size == 'small') {
              ipRowInfo = item.text;
              ipControlName="TextBlock"
            } else {
              if (parseInt(i) != 0) {
                // Push into array
                this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
                  ipControlName, ipControlData, ipRowInfo));
                // Reset variables
                ipRowLabel = ipControlType = ipControlName = ipControlData = ipRowInfo = null;
              }
              ipRowLabel = item.text;
              ipControlName="TextBlock"
            }
            break;
  
         
  
          case "Input.ChoiceSet":
            ipControlType = "Select";
            ipControlName = item.id;
            ipControlData = item;
            break;
  
          default:
            break;
        }
      }
  
      // Push the last row into array
      this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
      ipControlName, ipControlData, ipRowInfo));
  
      if(rawAdCard.content.actions){
          if(rawAdCard.content.actions[0].type == "Action.Submit"){
              this.shouldSendAsValue = true;
              let choices: Array<string>  = rawAdCard.content.actions.map( actionObj => {
                  return actionObj.title;
              } );
              ipControlType = "buttonChoice"
              ipControlData = choices
              this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
                  ipControlName, ipControlData, ipRowInfo));
          }
      }
    }
  
  
    onSelectDdCard(controlName: any,opt:any) {
    //   var dropdowns = document.getElementsByClassName("dropdown-content");
    // let i;
    // for (i = 0; i < dropdowns.length; i++) {
    //   let openDropdown = dropdowns[i];
    //   if (openDropdown.classList.contains('show')) {
    //     openDropdown.classList.remove('show');
    //   }
    // }
    this.showClass = !this.showClass
      this.ondisable = true;
      this.optioSelected = opt.title
      //this.ddOptionSelected = true
      //let data = this.DdSelect.split(":");
      let msg = {
              };
      msg[controlName] = opt.title;
      msg["optionId"] = opt.value;
        this.formValue.emit(msg);
    }

    myFunction() {
    //  let a = document.getElementsByClassName("dropdown-content").length
    //  document.getElementsByClassName("dropdown-content")[a-1].classList.toggle("show")
    this.showClass = !this.showClass
     //console.log("---->",a)
    }
    
  
  }
  
  
  export class FormCardIpRow {
    public ddOptions: Array<DdOptionModel>;
  
    populateOptions(data) {
      const choices = data.choices;
      this.ddOptions = [];
      // Push the options into array.
      for (let i in choices) {
        this.ddOptions.push(new DdOptionModel(choices[i].title, choices[i].value));
      }
    }
  
    constructor(
      public controlLabel: string,
      public controlType: any,
      public controlName: string,
      public controlData: any,
      public controlInfo: string,
    ) {
      if (controlType == "Select") {
        this.populateOptions(controlData);
      }
    };
  }
  
  
  export class DdOptionModel {
    constructor(
      public title: string,
      public value: string,
    ) { }
  }
  
  
  export enum InputType {
    TextBox,
    Select,
    TextArea,
  }