import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AppStateService } from '../services/app-state.service';
import swal from 'sweetalert';
import { I18nService } from '../services/i18n.service';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})

export class Login2Component implements OnInit, OnDestroy {

  public readonly env = environment;
  private countLoginInfoHit: number;
  private readonly maxLoginInfoHitCount = 5;
  public isLoginFormShown = false;
  public isLoginFirstTime = false;
  private username: string;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  userName: any;
  password: any;
  


  // get f() {
  //   return this.loginForm.controls;
  // }


  private i18nConfig(authObj: any) {
    this._i18nService.clearLanguages();
    this._i18nService.setLanguages([authObj.defaultLang]);
    // this._i18nService.setLanguages(authObj.availableLangs);
    this._i18nService.setDefaultLocale();
  }


  createAuthObjFromSession() {
    return {
      "bot_user": "ashmeet.aad@agilecomputingcloudcoeoutlo.onmicrosoft.com",
      "jwtToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFzaG1lZXQuYWFkQGFnaWxlY29tcHV0aW5nY2xvdWRjb2VvdXRsby5vbm1pY3Jvc29mdC5jb20iLCJpYXQiOjE1NjkzMjMwMzJ9.6JTi1AjfvS-6gcSwX2Z4oe4h4IYVIqN0a0u7ghgYs50",
      "directLineToken": "ew0KICAiYWxnIjogIlJTMjU2IiwNCiAgImtpZCI6ICJBT08tZXhGd2puR3lDTEJhOTgwVkxOME1tUTgiLA0KICAieDV0IjogIkFPTy1leEZ3am5HeUNMQmE5ODBWTE4wTW1ROCIsDQogICJ0eXAiOiAiSldUIg0KfQ.ew0KICAiYm90IjogIldpbGV5LUJvdCIsDQogICJzaXRlIjogIjVJNWx0aU41X2dvIiwNCiAgImNvbnYiOiAiOGp0aHYzTWRNTjJKTFViemR4VHZDTC1mIiwNCiAgIm5iZiI6IDE1NjkzMjMwMzIsDQogICJleHAiOiAxNTY5MzI2NjMyLA0KICAiaXNzIjogImh0dHBzOi8vZGlyZWN0bGluZS5ib3RmcmFtZXdvcmsuY29tLyIsDQogICJhdWQiOiAiaHR0cHM6Ly9kaXJlY3RsaW5lLmJvdGZyYW1ld29yay5jb20vIg0KfQ.Mt2BDxvkbieD7-5xSV4rRapJpTAuoMMW_kXZRV-pr3KD5iQpd-la6X2WI5oVn0JpxwfxnanV0r4W5ZDf2dNDn856lZO9V1poI6rEjCbSrb7cEVwSDHdUGrhx0n_OcQwEhSh3tMjZlHMjCDWERV2SIRgVYxINpIAZ5PnGf87PP1ztG9JW6meyTw9ssvdxGV5uDI_27i2zXmJ_hsOEU5HEUX027KwHQEVlXpqnuK89OCce0qeBy48ruHjW2vWQ-DXoJkR8t7zPPA3YQJXmeQ43rAJTz-QRCbyFCDfhjS6f_ivRZ1TFOLGgJzk0bqMILJnSnpMJKfzRDRY9hRwqQFgXkg",
      "defaultLang": "en",
      "availableLangs": [
        {
          "langId": 1,
          "availableLang": "de"
        }
      ]
    };
  }


  createAuthObj(authResponse: any, csdXAuthToken: string) {
    const data = authResponse["responseDetails"][0];

  console.log("inside createAuthObj = ",data);
    const authObj = {
      "bot_user": data['userId'],
      "jwtToken": data['jwtToken'],
      "csdXAuthToken": csdXAuthToken,
      "digitalId": data['digitalId'],
      "directLineToken": data['directLineToken'],
      "defaultLang": data['defaultLang'],
      "sendParamData": JSON.parse(localStorage.getItem("sendParamData")),
      "mail_id": data['digitalId'].toString()+"@duracell.com",
      "Browser_Language": localStorage.getItem("Browser_Language"),
      "availableLangs": data['languages']
      
    };
    console.log('[createAuthObj] authObj: ', JSON.stringify(authObj));
    return authObj;
  }


  private fetchLoginInfo(authToken: string) {
    this.spinnerService.show();
    this.countLoginInfoHit++;

    this.authService.fetchAuthInfo(authToken).subscribe(
      data => {
       // console.log('[fetchAuthInfo]: Success!!');
        //console.log('[fetchAuthInfo - Success]:data = ', data);
        //console.log('[fetchAuthInfo - Success] data["responseDetails"] = : ', data["responseDetails"][0]);
        //console.log('[fetchAuthInfo - Success] authToken = : ', authToken);

        this._appStateService.shouldShowGreeting = true;
	      this._appStateService.setVoiceSubscriptionDetails(data["responseDetails"][0]);
        const authObj = this.createAuthObj(data, authToken);
        this.i18nConfig(authObj);
        this.router.navigate(['/admin/chat-box'], { queryParams: { data: btoa(JSON.stringify(authObj)) } });
      },

      err => {
        console.log('[fetchAuthInfo - Error]: ', err);
        if (this.countLoginInfoHit <= this.maxLoginInfoHitCount) {
          console.log(`[fetchAuthInfo - Error] Retrying fetching details (Count - ${this.countLoginInfoHit})`);
          setTimeout(() => {
            console.log(`[fetchAuthInfo - Timeout] Initiating hit ...`);
            this.fetchLoginInfo(authToken);
          }, 3500);
        } else {
          this.spinnerService.hide();
          swal({
            text: 'Something went wrong! Please try again.',
            icon: 'error',
            className: 'swal-wide'
          });
        }
      });
  }

  dummyLogin(){
   
    
    let resp = {"responseStatus":"SUCCESS","responseMessage":"Authentication Successful","responseDetails":[{"success":true,"tenantId":null,"userId":"Bojanapu, KiranKumar","firstName":null,"lastName":null,"msg":"Authentication Successful","authToken":"3e5df1e8-1400-4a1f-b88e-1ab4ecdc37df","country":null,"languages":[{"langId":"1","availableLang":"en"}],"isFirstLogin":null,"isPasswordExpired":null,"sessionTime":null,"sessionPromptTime":null,"environment":null,"redirectUrl":null,"defaultLang":"en","directLineToken":"ew0KICAiYWxnIjogIlJTMjU2IiwNCiAgImtpZCI6ICJlTG1Ja0JEQXdMZHF1cG5EZGVhenJab3RZMzAiLA0KICAieDV0IjogImVMbUlrQkRBd0xkcXVwbkRkZWF6clpvdFkzMCIsDQogICJ0eXAiOiAiSldUIg0KfQ.ew0KICAiYm90IjogImNkZC1iY3ItdWF0IiwNCiAgInNpdGUiOiAiSUR3TFE4b09qZXMiLA0KICAiY29udiI6ICIyUVlReGgydHdMamM2UVV2STNqSEktNiIsDQogICJuYmYiOiAxNjI1MDc0MDU0LA0KICAiZXhwIjogMTYyNTA3NzY1NCwNCiAgImlzcyI6ICJodHRwczovL2RpcmVjdGxpbmUuYm90ZnJhbWV3b3JrLmNvbS8iLA0KICAiYXVkIjogImh0dHBzOi8vZGlyZWN0bGluZS5ib3RmcmFtZXdvcmsuY29tLyINCn0.pYnl8XJYX1EV4GvvJa4s3JBjS0PITw3hDgS_Le16QE3DOBQr-u5Hv8iCq4-YtYN2-jycxIy9CBfFRVSiSd1uSWa3aV1yoVDioMAJfJubp_Zf562pf8E7f0oJzua56mxxbIk6JSEdbAo7C2wqr_W4P7gBzv1qKSfutiU4XXO0NQW-P5AXd-TYc5NeyJQFq_BGKawbrC4nOEhYXbYNlRafGyVZPjayODAKmUKt80oHSLr1QFQtMjA3NSyIn3VRpqVSqg9qkPCg2Gr79tupfNXcwSI9S_9GdwskccRnMlBWzd1ZhwdSEI3mdpdSAf2UwIfZ2W2A7tjM5YluRd_7mElUQA","jwtToken":null,"digitalId":"bojanapu.k","voiceSubscription":{"subscriptionKey":"024f280e2e52463987774dcf0a41f070","serviceRegion":"eastus","endpointId":"625fd1f7-fc77-4249-b172-91ee23efc756","speechRecognitionLanguage":"en-US","serviceProvider":"Azure","status":"Active"}}],"success":true}

this._appStateService.shouldShowGreeting = true;
this._appStateService.setVoiceSubscriptionDetails(resp["responseDetails"][0]);
const authObj = this.createAuthObj(resp, '3e5df1e8-1400-4a1f-b88e-1ab4ecdc37df');
this.i18nConfig(authObj);
this.router.navigate(['/admin/chat-box'], { queryParams: { data: btoa(JSON.stringify(authObj)) } });
  }

  onSubmit() {
    
    console.log('[onSubmit] loginForm: ', this.loginForm);

    if (!environment.showAuthOption || this.loginForm.invalid) {
      return;
    }

    this.spinnerService.show();
    this.authService.azureAdAuthentication(this.loginForm.value).subscribe(
      resp => {
        const responseObj: any = resp;
        // console.log('[onSubmit] responseObj: ', responseObj);
        this.spinnerService.hide();

        if (responseObj.message) {
          
          // value of this item can be chat_ui_visited/chat_ui_not_visited
          localStorage.setItem(responseObj.message.csdXAuthToken, "chat_ui_not_visited");

          this._appStateService.username = responseObj.message.bot_user.split('@')[0];
          this._appStateService.shouldShowGreeting = true;
          this.i18nConfig(responseObj.message);
          this.router.navigate(['/admin/chat-box'], { queryParams: { data: btoa(JSON.stringify(responseObj.message)) } });
        } else {
          swal({
            text: 'Something went wrong! Please try again.',
            icon: 'error',
            className: 'swal-wide'
          });
        }
      },

      error => {
        this.spinnerService.hide();
        console.log('[onSubmit] ERROR: ', error);
        swal({
          text: 'Please enter valid credentials..!!',
          icon: 'error',
          className: 'swal-wide'
        });
      });
  }


  showLoginForm() {
    if (!environment.showAuthOption && this.isLoginFormShown) {
      return;
    }

    // Prepare the login form
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.isLoginFormShown = true;
  }

  getQueryParameter(){
  // alert("inside getQueryParameter");
    let queryParam;
    this._route.queryParamMap.subscribe(data => {
      queryParam = data['params'];
      localStorage.setItem("sendParamData", JSON.stringify(queryParam));
    });
  
   

  }


  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    private _appStateService: AppStateService,
    public _i18nService: I18nService,

  ) { 
   
  }


 

  

  ngOnInit() {
    
 
    localStorage.setItem("Browser_Language",this.getFirstBrowserLanguage());
     
   
   
  
    this._route.queryParams.subscribe(
    params => {
        const authToken = params['csd-x-auth-token'];
        console.log('[Login2 - ngOnInit] authToken retrieved!!!');
        
        console.log('[Login2 - ngOnInit] authToken: ', authToken);

        if (!authToken) {
          //alert("hi 1");
         this.getQueryParameter();
        
          window.location.href ="https://duracmbf.duracell.com/api/v2.0/login.do";
          return;
        }
      
      
        localStorage.setItem('csd-x-auth-token', authToken);
        // If authToken present in the URI then fetch the login details.
        this.countLoginInfoHit = 0;
        this.fetchLoginInfo(authToken);

        // Save the username from params
       // let uName: string = params['name'];
       // const idx = uName.indexOf('(')
       // this.userName = uName.substring(0, (idx > 0 ? idx : uName.length)).trim();
        console.log('[Login2 - ngOnInit] userName retrieved!');
        console.log('[Login2 - ngOnInit] userName: ', this.userName);
       

          
      });

     
  }

  
  getFirstBrowserLanguage = function () {
    var nav = window.navigator,
    browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
    i,
    language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  }

  
  ngOnDestroy() {
    this.spinnerService.hide();
    
  }

}
