import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'; // Vidhi
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { Pnf404Component } from './pnf404/pnf404.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Sidebar2Component } from './sidebar2/sidebar2.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { SharedService } from './services/sharedService';
import { DirectLineService } from './services/directLineService';
import { DirectLine, CardAction, IBotConnection, User } from './services/botframework-directlinejs';
import { HttpModule } from '@angular/http';
import { AutofocusModule } from 'angular-autofocus-fix';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { Login2Component } from './login2/login2.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { FormAdcardComponent } from './form-adcard/form-adcard.component';
import {DropdownCardComponent} from './dropdown-card/dropdown-card.component'
import {SigninComponent} from './auth/signin.component'
import {FeedbackFormCardComponent} from './feedback-form-card/feedback-form-card.component'
import { IframeModalComponent } from './iframe-modal/iframe-modal.component';
import { LocaleLoaderComponent } from './locale-loader/locale-loader.component';

import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [
    DropdownCardComponent,
    SigninComponent,
    FeedbackFormCardComponent,
    AppComponent,
    NavbarComponent,
    Pnf404Component,
    DashboardComponent,
    Sidebar2Component,
    DashboardHomeComponent,
    ChatBoxComponent,
    Login2Component,
    FormAdcardComponent,
    IframeModalComponent,
    LocaleLoaderComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpModule,
    AutofocusModule,
    Ng4LoadingSpinnerModule.forRoot(),
    AngularDateTimePickerModule
  ],
  providers: [SharedService, DirectLineService],
  bootstrap: [AppComponent]
})

export class AppModule { }
