
import { DirectLine, CardAction, IBotConnection, User } from '../services/botframework-directlinejs';
import { AdaptiveCard, Action, HostConfig, OpenUrlAction, SubmitAction } from "adaptivecards";
import { IAction, IAdaptiveCard, IOpenUrlAction, IShowCardAction, ISubmitAction } from 'adaptivecards/lib/schema';
import { Conversation, hostConfig } from './chat';
// import { RenderMessages } from './RenderMessages';

export class RenderCards {

    static renderCards: RenderCards;

    public adaptiveCard(isHostConfigReq?: boolean): any {
        var adaptiveCard = new AdaptiveCard();
        if (isHostConfigReq) {
            adaptiveCard.hostConfig = new HostConfig(hostConfig);
        }
        return adaptiveCard;
    }


    public filterMessage(message: any): any {
        var card = message.attachments[0].content || message.attachments[0].adaptiveCard.content;
        var data = { "cardData": card, "adaptiveCard": this.adaptiveCard(true), "cardType": "adaptiveCard" };
        try {
            if (message.channelData.cardType === "richcard" && message.channelData.dataType === "ticket") {
                data.adaptiveCard = this.adaptiveCard();
                data.cardType = "customAdaptiveCard";
            }
        } catch (e) { }
        return data;
    }


    public renderAdaptiveCard(card: any, id: any) {
        const custHtml = document.getElementsByClassName("custBot");
        var div = custHtml[(custHtml.length) - 1];
        div.setAttribute("id", id);
        div.setAttribute("style", "min-width: 325px;width:75%");
        document.getElementById(id).appendChild(card);
    }

    public renderText(card: any, id: any) {
        const custHtml = document.getElementsByClassName("custBot");
        var div = custHtml[(custHtml.length) - 1];
        div.setAttribute("id", id);
        div.setAttribute("style", "min-width: 325px;width:75%");
        document.getElementById(id).appendChild(card);
    }

    public renderCustomAdaptiveCard(card: any, id: any) {
        const custHtml = document.getElementsByClassName("custBot");
        var div = custHtml[(custHtml.length) - 1];
        div.setAttribute("id", id);
        div.setAttribute("style", 'background: url("/assets/Icons/Update_Ticket_white.svg") no-repeat right center, linear-gradient(to right, #B164F9, #6764F9); width:400px;');
        document.getElementById(id).appendChild(card);
        var cardDiv = document.getElementById(id).getElementsByClassName('ac-container')[0];
        cardDiv.setAttribute("style", 'display: flex; flex-direction: column; justify-content: flex-start; box-sizing: border-box; flex: 0 0 auto; padding: 20px;');

    }


}

