import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-feedback-form-card',
  templateUrl: './feedback-form-card.component.html',
  styleUrls: ['./feedback-form-card.component.css']
})
export class FeedbackFormCardComponent implements OnInit {

  @Input() formStructure: any;
  @Output() formValue = new EventEmitter();

  public isFormReady: boolean = false;
  public formRows: Array<FormCardIpRow> = [];
  public enInputType = InputType;
  public errorTimer: any;
  public isErrorShown: boolean = false;
  public markedChoicesFromYesNo ={ "type" : "Submit"}
  public showButton : boolean;


  parseAndInit(rawAdCard) {
    const cardBody: Array<any> = rawAdCard.content.body 
    let ipRowLabel: string, ipControlData: any, ipRowInfo: string,
      ipControlType: InputType, ipControlName: string,ipControlChoices: any,ipControlPlaceholder:any,ipChoiceSelected:any

    // Loop through the object and parse into appropriate structures.
    for (let i in cardBody) {
      const item = cardBody[i];

      switch (item.type) {
        case "TextBlock":
          // Label or Info
          if (item.size && item.size == 'small') {
            ipRowInfo = item.text;
          } else {
            if (parseInt(i) != 0) {
              // Push into array
              this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
                ipControlName, ipControlData, ipRowInfo, ipControlChoices, ipControlPlaceholder,ipChoiceSelected));
              // Reset variables
              ipRowLabel = ipControlType = ipControlName = ipControlData = ipRowInfo = null;
            }
            ipRowLabel = item.text;
          }
          break;

        case "Input.Text":
          ipControlType = InputType.TextBox;
          ipControlName = item.id;
          ipControlPlaceholder = item.placeholder
          break;

        case "Input.ChoiceSet":
          ipControlType = InputType.YesNoChoice;
          ipControlChoices = item.choices
          ipControlName = item.id
          break;

        default:
          break;
      }
    }

    // Push the last row into array
    this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
    ipControlName, ipControlData, ipRowInfo, ipControlChoices, ipControlPlaceholder,ipChoiceSelected));
    
    if(rawAdCard.content.actions){
       if(rawAdCard.content.actions[0].type == "Action.Submit"){
      //     this.shouldSendAsValue = true;
      //     let choices: Array<string>  = rawAdCard.content.actions.map( actionObj => {
      //         return actionObj.title;
      //     } );
          ipControlType = InputType.SubmitButton
          ipControlName = rawAdCard.content.actions[0].title
          ipRowLabel = ""
          ipControlData = ""
          ipRowInfo = ""
          ipControlChoices = ""
          ipControlPlaceholder = ""
          ipChoiceSelected = ""
          this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,
            ipControlName, ipControlData, ipRowInfo, ipControlChoices, ipControlPlaceholder,ipChoiceSelected));
      }
    }
    // Activate form
    this.isFormReady = true;
  }

  sendMarkedOption(choice:any,que:any,idx:any,ipRow:any){
    ipRow.ipChoiceSelected = idx
    this.markedChoicesFromYesNo[que] = choice
  }
  private isAllFieldsFilled(formData){
    for( let keys in formData ){
      if( formData[keys] == '' ){
        return false;
      }
    }
    return true;
  }


  submitForm(formData) {
    this.showButton = false
    let merged = {...formData , ...this.markedChoicesFromYesNo}
    console.log("final form",merged)
    this.formValue.emit(merged);
    
  }


  // TODO: Handle form re-send!!
  showError(){
    clearTimeout(this.errorTimer);
    this.isErrorShown = true;

    this.errorTimer = setTimeout(() => {
      this.isErrorShown = false;
    }, 10000);
  }


  constructor() {
    this.showButton = true
   }


  ngOnInit() {
    this.parseAndInit(this.formStructure);
  }

}


export class FormCardIpRow {
  //public ddOptions: Array<DdOptionModel>;

  // populateOptions(data) {
  //   const choices = data.choices;
  //   this.ddOptions = [];
  //   // Push the options into array.
  //   for (let i in choices) {
  //     this.ddOptions.push(new DdOptionModel(choices[i].title, choices[i].value));
  //   }
  // }

  constructor(
    public controlLabel: string,
    public controlType: InputType,
    public controlName: string,
    public controlData: any,
    public controlInfo: string,
    public controlChoices : any,
    public ControlPlaceholder : any,
    public ipChoiceSelected : any
  ) {
    // if (controlType == InputType.Select) {
    //   this.populateOptions(controlData);
    // }
  };
}


// export class DdOptionModel {
//   constructor(
//     public title: string,
//     public value: string,
//   ) { }
// }


export enum InputType {
  TextBox,
  YesNoChoice,
  TextArea,
  SubmitButton
}
