import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { DirectLine } from './botframework-directlinejs';


@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  // Global values
  public username: string = "** Dev **";
  
  public resizeWidth$: Observable<any>;
  public resizeHeight$: Observable<any>;
  public currHeight: number;
  public currWidth: number;
  public readonly mobileWidth: number = 900;
  public shouldShowGreeting: boolean = false;
  public isIframeShown: boolean = false;
  public urlIframe: string = "https://dev69569.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=6370412e3771300054b6a3549dbe5d82&sysparm_link_parent=5d643c6a3771300054b6a3549dbe5db0&sysparm_catalog=undefined&sysparm_catalog_view=catalog_default&sysparm_view=catalog_default";
  public isActionMsgShown: boolean = false;
  public voiceSubscriptionDetails: any;

  public dlObj: DirectLine;


  private monitorWinResize(){
    // Observe Width
    this.resizeWidth$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerWidth)
      .distinctUntilChanged()
      .startWith(window.innerWidth);

    this.resizeWidth$.subscribe(
      data => {
        this.currWidth = data;
        console.log("** Width resized", data);
      }
    );

    // Observe Height
    this.resizeHeight$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerHeight)
      .distinctUntilChanged()
      .startWith(window.innerHeight);

    this.resizeHeight$.subscribe(
      data => {
        this.currHeight = data;
        console.log("** Height resized", data);
      }
    );
  }

  public setVoiceSubscriptionDetails(data: any){
    this.voiceSubscriptionDetails=data["voiceSubscription"];
  }

	public getVoiceSubscriptionDetails(){
    return this.voiceSubscriptionDetails;
  }

  
  constructor() {
    this.monitorWinResize();
  }
}