/*-------------------------------------------------------*/
/* KP - dashboard-home component replacement */
/*-------------------------------------------------------*/

import { Component, OnInit, AfterViewInit, ElementRef, ViewChild,OnDestroy } from '@angular/core';

import { DirectLine, CardAction, IBotConnection, User, ConnectionStatus, Activity } from '../services/botframework-directlinejs';
import { SharedService } from '../services/sharedService';
import { DirectLineService } from '../services/directLineService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChatMessageModel, MsgType, MsgAuthor } from '../models/chat-message-model';
import { NewTktCardModel, TicketType } from '../models/new-tkt-card-model';
import { TktStatusCardModel } from '../models/tkt-status-card-model';
import { InfoCardModel } from '../models/info-card-model';
import { isUndefined } from 'util';
import { chatBubbleAnimation, botAvatarAnimation, cardItemAnimation, userAvatarAnimation, cardBtnAnimation } from '../utils/chat-animation'
import { FormattedTextModel } from '../models/formatted-text-model';
import { ChoiceCardModel } from '../models/choice-card-model';
import { ActionCardModel } from '../models/action-card-model';
import { OutageCardModel } from '../models/outage-card-model';
import { FormAdcardComponent } from '../form-adcard/form-adcard.component';
import { NewTktCardModelAD, TicketTypeAD } from '../models/new-tkt-card-model-AD';
import { AppStateService } from '../services/app-state.service';
import { DecoratedText, TextDecorationType } from "../models/DecoratedText";
// import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { Subscription } from 'rxjs';
import { I18nService } from '../services/i18n.service';
import { MultiContentCardModel, ContentType } from '../models/multi-content-card-model';
//import { AdaptiveCard, Action, HostConfig, IValidationError, OpenUrlAction, SubmitAction } from 'adaptivecards';
import * as AdaptiveCards from "adaptivecards";
//import { IAction, IAdaptiveCard, IOpenUrlAction, IShowCardAction, ISubmitAction } from 'adaptivecards/lib/schema';
import { adaptiveCards } from 'botframework-webchat/built/Store';
import { Action, AdaptiveCard, SubmitAction } from 'adaptivecards';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
declare var $: any;
let self: ChatBoxComponent;

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css'],
  animations: [chatBubbleAnimation, botAvatarAnimation, userAvatarAnimation, cardItemAnimation, cardBtnAnimation],
})
export class ChatBoxComponent implements OnInit, OnDestroy {
  //@ViewChild('poDetailsBody') poDetailsBody: ElementRef;
  public conversation: Array<ChatMessageModel> = [];
  private _conversationCustomIdLedger: Array<number> = [];
  private ackPendingIdxList: Array<number> = [];
  private reactPendingIdxList: Array<number> = [];
  private isCollectingInfoArticles: boolean = false;
  private _currConnState: ConnectionStatus;
  private subscription: Subscription = new Subscription();
  clickEventsubscription:Subscription;

  // Enums have been re-declared to be able to referenced in templates.
  public enMsgType = MsgType;
  public enMsgAuthor = MsgAuthor;
  public enTextDecorationType = TextDecorationType;
  public enMconTextType = ContentType;
  private _timerTokenRefresh;
  public i18nTexts: any;
  public  PodetailsAdaptiveCard :any;
  public  agreementAdaptiveCard :any;

  public  getQtycardAdaptiveCard:any;

  // The below three variables should only be set by the method showModalMsg()
  public _shouldShowModalMsg: boolean = false;
  public _modalMsgBody: string;
  public _modalMsgType: number;
  public _isTxtDisabled: boolean = false;
  private _scrollTimer: any;
  private _micTimer: any;
  public _isPoDetail : boolean = true;
  public poDetailId:any;
  public agreementId:any;

  public getQtyCardId:any;
  public EmptyMsgID:any;
  
  public  attempt:any;

  public readonly statusCardDefaultCount: number = 3;
  public readonly infoCardDefaultCount: number = 3;
  public readonly outageCardDefaultCount: number = 3;

  /*-------------------------------------------------------*/
  /* Voice Recognition implementation */
  /*-------------------------------------------------------*/
  public isListening: boolean = false;
  public SpeechSDK;
  // public reco: SpeechSDK.SpeechRecognizer;
  public voiceSubscriptionDetails: any;

  // directLineObj: DirectLine;
  textToSpeechmsg:any
  directLineObj: any;
  msgToBot: string;
  userName: string;
  authObj: any;
  geoLocationObj: any = { 'country': 'Netherlands', 'city': 'Amsterdam' };
  directLineToken: string;
  jwtToken: string = '';
  digitalId: string = '';
  sysId: string = '';
  sendCalDate: boolean = true;
  CurrentFile: any;
  ImageSource: any;
  mail_id:any;
  sendParamDataID:any;
  Browser_Language:any;
 

  
 
/*
   
  @ViewChild('poDetailsBody') poDetailsBody: ElementRef;

  setTimeout( () => {
    this.poDetailsBody.nativeElement.click();
    }, 200);
    */


  fetchParamsData(params: any) {
    // let data = atob(params['data'] || '');
    
    // if (data !== '') {
    //   self.authObj = JSON.parse(data);
    //   let isVisited = localStorage.getItem(self.authObj.csdXAuthToken);
    //   if(isVisited==null || isVisited=="chat_ui_not_visited"){
    //   // Set usernames
    //     // self.userName = self.authObj.bot_user.substring(0, self.authObj.bot_user.lastIndexOf("@"));
    //     self.userName = self.authObj.bot_user;
    //     this._appStateService.username = self.userName;

    //     // Set tokens
    //     self.directLineToken = self.authObj.directLineToken;
    //     self.jwtToken = self.authObj.jwtToken;

    //     localStorage.setItem(self.authObj.csdXAuthToken, "chat_ui_visited");
    //   }else{

    //     this.router.navigate(['/login']);
        
    //   }
 
    // }
    // else {
    //   this.router.navigate(['/login']);
    // }
    let data = atob(params["data"] || "");
    if (data !== "") {
     // let subParameter="'mail_id':"+ self.digitalId.toString()+"@duracell.com"+",'sendParamData:'"+localStorage.getItem("sendParamData")+",'Browser_Language:'"+localStorage.getItem("Browser_Language");
     // data+=subParameter;
      self.authObj = JSON.parse(data);

      // Set usernames
      // self.userName = self.authObj.bot_user.substring(0, self.authObj.bot_user.lastIndexOf("@"));
      self.userName = self.authObj.bot_user;
      this._appStateService.username = self.userName;

      // Set tokens
      self.directLineToken = self.authObj.directLineToken;
      console.log("....",self.directLineToken,self.authObj.directLineToken)
      self.jwtToken = self.authObj.jwtToken;
      self.digitalId=self.authObj.digitalId;
      this.jwtToken = self.authObj.jwtToken;
      self.mail_id=self.digitalId.toString()+"@duracell.com";
      self.sendParamDataID= JSON.parse(localStorage.getItem("sendParamData"));
      self.Browser_Language=localStorage.getItem("Browser_Language");

    } else {
      this.router.navigate(["/login"]);
    }
  }


  

  subscribeMessages() {
    // Subscribe to all activities
    this.subscription.add(
      this.directLineObj.activity$
        .subscribe(data => {
         
          console.log(" ** ACTIVITY:", data);
      


        })
    );

    // Handle Typing event
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return (activity.type === 'typing');
        })
        .subscribe(message => {
          console.log("Identified typing event!!");
          this._appStateService.isActionMsgShown = true;
        })
    );

    // Handle messages from the USER.
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return (activity.type === 'message') && (activity.from.id === self.userName);
        })
        .subscribe(message => {
          console.log("User msg: ", message);
          let msgId: number = parseInt(message.id.split('|')[1]);
          let customId: number = message.channelData.customUserId;
          // TODO: Properly handle out-of-order messages.
          if (this._conversationCustomIdLedger.includes(customId)) {
            // The message is a fresh one. Acknowledge it!
            this.acknowledgeUserMessage(message);
          } else {
            // The message is from history.
           // alert(message.text);
           

              //console.log(" Handle messages from the USER.attempt=",this.attempt);
             
            let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.User, message.text, MsgType.TextOnly, new Date(message.timestamp), false);
            
            this.addMsgToConversation(newMsg);

            if(message.text==""){
             document.getElementsByClassName("bubble-container")[0].innerHTML="";

            }
            
            
            
              
           
                 //get paramData
         
          }
        })
    );

    // Handle messages from the BOT.
    this.subscription.add(
      this.directLineObj.activity$
        .filter(activity => {
          return activity.type === 'message' && (activity.from.id !== self.userName);
        })
        .subscribe(message => {
          console.log("Bot msg: ", message);
          message.channelData1=self.authObj;
          console.log("Bot msg1: ", message);
          let currMsgBody: any;
          let currMsgType: MsgType;
          let currMsgTime: Date = new Date(message.timestamp);
          
          //this.textToSpeechClient(message.text)
              
          // Hide typing message
          this._appStateService.isActionMsgShown = false;
          
          if (message.attachments && message.channelData) {
            // Process Adaptive Cards
            let dataType = message.channelData.dataType;
            let rawAdaptiveCard = message.attachments;
          
          
            if (rawAdaptiveCard[0].content.body) {

              // switch (numDataBlocks) {
              switch (dataType) {
                case "CARD_INFO_RA":
                  // RightAnswer response
                  self.addInfoCardToConversation(rawAdaptiveCard, currMsgTime);
                  break;

                case "CARD_ITSM_INC_NEW":
                case "CARD_ITSM_INC_Existing":
                case "CARD_ITSM_SR_NEW":
                  // Single Incident/SR Card
                  let isNew: boolean = (dataType === "CARD_ITSM_INC_NEW" || dataType === "CARD_ITSM_SR_NEW") ? true : false;
                  let ticketType: TicketType = (dataType === "CARD_ITSM_SR_NEW" ? TicketType.ServiceRequest : TicketType.Incident);
                  let newTicketCard = new NewTktCardModel(rawAdaptiveCard, isNew, ticketType);
                  console.log("newTicketCard: ", newTicketCard);
                  currMsgBody = newTicketCard;
                  currMsgType = MsgType.NewTicketCard;
                  break;

                // case "CARD_ITSM_INC_NEW_AD":
                //   let isNewAD: boolean = (dataType === "CARD_ITSM_INC_NEW_AD" || dataType === "CARD_ITSM_SR_NEW") ? true : false;
                //   let ticketTypeAD: TicketTypeAD = (dataType === "CARD_ITSM_SR_NEW" ? TicketTypeAD.ServiceRequest : TicketTypeAD.Incident);
                //   let newTicketCardAD = new NewTktCardModelAD(rawAdaptiveCard, isNewAD, ticketTypeAD);
                //   console.log("newTicketCard: ", newTicketCardAD);
                //   currMsgBody = newTicketCardAD;
                //   currMsgType = MsgType.NewTicketCardAD;
                //   break;

                case "CARD_ITSM_INC_STATUS":
                  // Status Card
                  currMsgType = MsgType.StatusCardCollection;
                  currMsgBody = self.parseRawToStatusCards(rawAdaptiveCard);
                  break;

                case "CARD_ITSM_OUTAGE":
                  // Outage Card
                  currMsgType = MsgType.OutageCard;
                  currMsgBody = new OutageCardModel(rawAdaptiveCard);
                  break;

                // TODO: Change the datatype of this card.
                case "CARD_ITSM_INC_UPDATECLOSE":
                  // Choice Card - with actions
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "CARD_AD":
                  // Form card
                  currMsgType = MsgType.FormCard;
                  currMsgBody = rawAdaptiveCard[0];
                  break;
                case "Podetails":
                 // this._isPoDetail=true;
                    currMsgType = MsgType.Podetails;
                    currMsgBody = rawAdaptiveCard[0].content;
                    
                     break;
                case "getQtyCard":
                      currMsgType = MsgType.getQtyCard0;
                      currMsgBody = rawAdaptiveCard[0].content;
       
                      break;

                case "AGREEMENT_CARD":
                      currMsgType = MsgType.AGREEMENT_CARD;
                      currMsgBody = rawAdaptiveCard[0].content;
         
                      break;

                case "CARD_FAQ":
                  // FAQ Card - with multiple content types
                  currMsgType = MsgType.FaqCard;
                  currMsgBody = new MultiContentCardModel(rawAdaptiveCard);
                  break;



               

                default:
                  // Unknown Card
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = "ERROR: " + dataType + " is not yet implemented!! Kindly report to the dev team." +
                    JSON.stringify(rawAdaptiveCard);
              }
            } else {
              currMsgType = MsgType.TextOnly;
              currMsgBody = rawAdaptiveCard[0].content.title;
              
            }
          } else if ((message.attachments || message.adaptiveCard) && !message.channelData) {
            // Adaptive cards without any channel-data
            // TODO: Remove the temporary solution below.
            let rawCard = (message.adaptiveCard) ? message.adaptiveCard : message.attachments[0];
            if (rawCard.content.actions) {
              // Action Card
              currMsgType = MsgType.ActionCard;
              currMsgBody = new ActionCardModel(rawCard);
            } else if (rawCard.contentType === "application/vnd.microsoft.card.video") {
              // Video Card
              currMsgType = MsgType.VideoCard;
              currMsgBody = rawCard.content.media[0].url;
            } else {
              // Error Message
              currMsgType = MsgType.TextOnly;
              currMsgBody = "ERROR: Unidentified attachment!! Kindly report to the dev team." +
                JSON.stringify(rawCard);
            }
          } else {
            // Text or Choice response
            if (message.channelData) {
              let dataType = message.channelData.dataType;

              switch (dataType) {
                case "TEXT_FORMATTED":
                  // Formatted Text
                  currMsgType = MsgType.TextFormatted;
                  currMsgBody = new FormattedTextModel(message.text);
                  break;

                case "YES_NO":
                  // Choice Card
                  currMsgType = MsgType.YesNoCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "FEEDBACK_START":
                  // Feedback Card
                  currMsgType = MsgType.ThumbsCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "OTHER_CHOICE":
                  // Choice Card
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "calendar":
                case "calender":
                  // Calendar Card
                  currMsgType = MsgType.Calender;
                  currMsgBody = message.text;
                  break;

                default:
                  // TODO: Remove this... temporary solution!!! Write an error instead.
                  // Plain Text
                  if (message.channelData.rawData) {
                    self.sysId = message.channelData.rawData;
                  }
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = message.text;
                  break;
              }
            } else {
              // Plain Text
              currMsgType = MsgType.TextOnly;
              currMsgBody = message.text;
            }

            if (self.isCollectingInfoArticles) {
              self.isCollectingInfoArticles = false;
            }
          }

          if (!self.isCollectingInfoArticles) {

           
            let newMsg = new ChatMessageModel(MsgAuthor.Bot, currMsgBody, currMsgType, currMsgTime);

               console.log("newMsg=",newMsg);
               console.log("currMsgBody=",currMsgBody);
            
                self.addMsgToConversation(newMsg);
                //this.getOtherParameter();
                      
                if(document.getElementsByClassName("bubble-container")[0].innerHTML!=""){
                  this.EmptyMsgID=setInterval(function(){ 
                
                    if(document.getElementsByClassName("bubble-container")[0].innerHTML!=""){
                    
                      document.getElementsByClassName("bubble-container")[0].innerHTML="";
                    
                    }
                  }, 1);

                }else{
                  clearInterval(this.EmptyMsgID);
                }
                if(currMsgType.toString()=="15"){
                 
                  this.poDetailId =setInterval(function(){ 
                    let class_length=document.getElementsByClassName("poDetailsBody").length;
                    if(document.getElementsByClassName("poDetailsBody")[class_length-1].innerHTML==""){
                      let div_class_length=class_length-1;
                      document.getElementsByClassName("poDetailsBody_div")[class_length-1].id="poDetailsBody_div_"+div_class_length;
                      document.getElementById("poDetailsBody_div_"+div_class_length).click();
                    }
                      
                  }, 10);
                  
                }else{
                  clearInterval(this.poDetailId);
                }
                if(currMsgType.toString()=="16"){
                 
                  this.getQtyCardId =setInterval(function(){ 
                    let class_length=document.getElementsByClassName("getQtyCardBody_0").length;
                    if(document.getElementsByClassName("getQtyCardBody_0")[class_length-1].innerHTML==""){
                      let div_class_length=class_length-1;
                      document.getElementsByClassName("getQtyCardBody_0_div")[class_length-1].id="getQtyCardBody_0_div_"+div_class_length;
                      document.getElementById("getQtyCardBody_0_div_"+div_class_length).click();
                    }
                      
                  }, 10);
                  
                }else{
                  clearInterval(this.getQtyCardId);
                }

                if(currMsgType.toString()=="17"){
                 
                  this.agreementId =setInterval(function(){ 
                    let class_length=document.getElementsByClassName("agreementDetailsBody").length;
                    if(document.getElementsByClassName("agreementDetailsBody")[class_length-1].innerHTML==""){
                      let div_class_length=class_length-1;
                      document.getElementsByClassName("agreementDetailsBody_div")[class_length-1].id="agreementDetailsBody_div_"+div_class_length;
                      document.getElementById("agreementDetailsBody_div_"+div_class_length).click();
                    }
                      
                  }, 10);
                  
                }else{
                  clearInterval(this.agreementId);
                }

                
          }

                self.scrollToBottom();
        })
    );
  }

  
  


  setAdaptiveCard(msgBody:any){
    document.getElementById("poDetailsBody").appendChild(msgBody);
    return true;
  }

 public  renderAdaptiveCard_Podetails(divID:any,card:any){
    console.log("card =",card);
    var adaptiveCard = new AdaptiveCards.AdaptiveCard();
    
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
        fontFamily: "Segoe UI, Helvetica Neue, sans-serif",
        class:"ac-container,ac-adaptiveCard,ac-columnSet",
        type:"AdaptiveCard",
        actions: {
          "maxActions": 1
          },
          
     
        // More host config options
    });

    adaptiveCard.parse(card);
    var renderedCard = adaptiveCard.render();
    
    // Provide an onExecuteAction handler to handle the Action.Submit

   // document.body.appendChild(renderedCard);
   // Provide an onExecuteAction handler to handle the Action.Submit
    adaptiveCard.onExecuteAction = (action: Action) => {
      if (action instanceof SubmitAction) {
          // If you copy this code sample, remove the alert statement
          // and provide your own custom handling code
          const myJSON = JSON.stringify(action.data);
        
          const myObj = JSON.parse(myJSON);
          console.log(myObj,"poObj");
          
          let flag = 0;
          for (let x in myObj) {
            if(myObj[x]!="false"){
                flag =1;
                break;
            }
          }
          console.log(flag);
         if(flag==1){
                  console.log(JSON.stringify(action.data));
                  console.log(JSON.stringify(action));
                 
                  localStorage.setItem('lang', action.id);
                  
                  this.postMsgToBot(action.data,false);
              this.disableAdaptiveCardSubmitButton();
      
         }else{
          alert("Please select at least one line!");
         }
      }
    }
  


let class_length=document.getElementsByClassName("poDetailsBody").length;

console.log(class_length);

    document.getElementsByClassName("poDetailsBody")[class_length-1].appendChild(renderedCard);

    // your JS here
    


    
//this._isPoDetail=false;
    return true;
}


public  renderAdaptiveCard_agreementdetails(divID:any,card:any){
  console.log("card =",card);
  var adaptiveCard = new AdaptiveCards.AdaptiveCard();
  
  adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif",
      class:"ac-container,ac-adaptiveCard,ac-columnSet",
      type:"AdaptiveCard",
      actions: {
        "maxActions": 2
        },
        
   
      // More host config options
  });

  adaptiveCard.parse(card);
  var renderedCard = adaptiveCard.render();
  
  // Provide an onExecuteAction handler to handle the Action.Submit

 // document.body.appendChild(renderedCard);
 // Provide an onExecuteAction handler to handle the Action.Submit
  adaptiveCard.onExecuteAction = (action: Action) => {
    if (action instanceof SubmitAction) {
        // If you copy this code sample, remove the alert statement
        // and provide your own custom handling code
        const myJSON = JSON.stringify(action.data);
      
        const myObj = JSON.parse(myJSON);
        console.log(myObj,"on obj");
        
        let flag = 0;
        for (let x in myObj) {
          if(myObj[x]!="false"){
              flag =1;
              break;
          }
        }
        console.log(flag);
         console.log(JSON.stringify(action.data));
        console.log(JSON.stringify(action));
        localStorage.setItem('lang', action.id);
        this.postMsgToBot(action.data,false);
        this.disableNewAdaptiveCardSubmitButtons();
      //  if(flag==1){
      //           console.log(JSON.stringify(action.data));
      //           console.log(JSON.stringify(action));
               
      //           localStorage.setItem('lang', action.id);
                
      //           this.postMsgToBot(action.data,false);
      //       this.disableAdaptiveCardSubmitButton();
    
      //  }else{
      //   alert("Please select at least one line!");
      //  }
    }
  }



let class_length=document.getElementsByClassName("agreementDetailsBody").length;

console.log(class_length);

  document.getElementsByClassName("agreementDetailsBody")[class_length-1].appendChild(renderedCard);

  // your JS here
  


  
//this._isPoDetail=false;
  return true;
}




public disableAdaptiveCardSubmitButton(){
  let button_length=document.getElementsByClassName("ac-pushButton").length;
  
  button_length=button_length-1;
  console.log(button_length);
  
  document.getElementsByClassName("ac-pushButton")[button_length].id="ac-pushButton"+button_length;
  document.getElementById("ac-pushButton"+button_length).style.display = "none";
}

public disableNewAdaptiveCardSubmitButtons(){
  let button_length=document.getElementsByClassName("ac-pushButton").length;
  // document.getElementsByClassName("ac-pushButton")[button_length].id="ac-pushButton"+button_length;
  // document.getElementById("ac-pushButton"+button_length).style.display = "none";
  button_length=button_length-1;
  console.log(button_length);

  document.getElementsByClassName("ac-pushButton")[button_length].id="ac-pushButton"+button_length;
  document.getElementById("ac-pushButton"+button_length).style.display = "none";
  button_length=button_length-1;
  document.getElementsByClassName("ac-pushButton")[button_length].id="ac-pushButton"+button_length;
  document.getElementById("ac-pushButton"+button_length).style.display = "none";

}
public  renderAdaptiveCard_getQtyCard0(divID:any,card:any){
 
  var adaptiveCard = new AdaptiveCards.AdaptiveCard();
  adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
    type:"AdaptiveCard",
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
      // More host config options
  });

  adaptiveCard.parse(card);
  var renderedCard = adaptiveCard.render();
  
  // Provide an onExecuteAction handler to handle the Action.Submit

 // document.body.appendChild(renderedCard);
 // Provide an onExecuteAction handler to handle the Action.Submit
  adaptiveCard.onExecuteAction = (action: Action) => {
    if (action instanceof SubmitAction) {
        // If you copy this code sample, remove the alert statement
        // and provide your own custom handling code
       
                        console.log(JSON.stringify(action.data));
                        console.log(JSON.stringify(action));
       
        //let Podetails_data=action.data;
        //localStorage.setItem('lang', action.id);
        this.postMsgToBot(action.data,false);
        this.disableAdaptiveCardSubmitButton();
    }
  }
  let class_length=document.getElementsByClassName("getQtyCardBody_0").length;

  console.log(class_length);
  
  document.getElementsByClassName("getQtyCardBody_0")[class_length-1].appendChild(renderedCard);
  
  return true;
}




  // NOTE: The text from the text-area is stored in "msgToBot".
  sendMsgToBot() {
    if (!isUndefined(this.msgToBot) && this.msgToBot != '') {
      // self.checkDirectlineConnection();
      if (this._currConnState == ConnectionStatus.Online) {
        self.postMsgToBot(this.msgToBot);
      } else {
        console.log("** ERROR: Connection interrupted! Cannot send messages to bot.");
      }

      // Clear the text-area
      this.msgToBot = '';
    }
  }


  clickedfromLeftMenu(msg: string) {
    self.msgToBot = msg;
    self.sendMsgToBot();
  }


  sendChoiceResponse(card: ChoiceCardModel, selectedIdx: number, conversationIdx: number) {
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choices[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.choices[selectedIdx], false);
        } else {
          self.postMsgToBot(card.choices[selectedIdx]);
        }
        card.markOption(selectedIdx);
      }
    }
  }


  private checkDirectlineConnection() {
    this.subscription.add(
      self.directLineObj.connectionStatus$
        .subscribe(connectionStatus => {
          this._currConnState = connectionStatus;
          console.log("** Connection Status: ", connectionStatus);

          switch (connectionStatus) {
            case ConnectionStatus.Uninitialized:
              // self._modalMsgBody = null;
              this.showModalMsg(1, "Trying to connect...");
              break;

            case ConnectionStatus.Connecting:
              // self._modalMsgBody = null;
              this.showModalMsg(1, "Connecting...");
              break;

            case ConnectionStatus.Online:
              // self._modalMsgBody = null;
              // this.showHideModalMsg(1, "Connected!!");
              
              this.showModalMsg(1, "Connected!!");
              this.hideModalMsg();
              
                
               
              break;

            case ConnectionStatus.ExpiredToken:
              this.showModalMsg(2, "");
              break;

            case ConnectionStatus.FailedToConnect:
              this.showModalMsg(2, "Connection failure! Please login again.");
              break;

            case ConnectionStatus.Ended:
              this.showModalMsg(2, "Session expired! Please login again.");
              break;

            default:
              break;
          }

          console.log("** Connection MSG:\n", self._modalMsgBody);
        })
    );
  }


  private postLoginSuccess() {
    var combined = {
      mail_id: self.digitalId.toString()+"@duracell.com",
      Browser_Language:localStorage.getItem("Browser_Language"),
      sendParamData: JSON.parse(localStorage.getItem("sendParamData"))
    }
    var str = JSON.stringify(combined);
    let str1=JSON.parse(str);
    console.log("str1=",str1);
    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'UserLoginSuccess',
      type: 'event',
      value: str1

    }).subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
    });
  }

  public getOtherParameter(){
     // POST the message.
     self.directLineObj.postActivity(this.createUserMsgPayload("", 1001))
     .subscribe(message => {
       console.log(`** postMsgToBot Success1: ${JSON.stringify(message)}`);

       // Handle resending
       if (!message.includes('|')) {
         // Show resend icons for all pending messages.
         console.log("Retry sending the message!!");
         this.flagPendingMsgsForRetry();
       }
       return;
     },
       error => {
         console.log(`** postMsgToBot ERROR: ${JSON.stringify(error)}`);

         // Acknowledge the message receipt as NOT sent and show retry button.
         // self.postedErrorMsg(usrmsg);
         return;
       });
  }
 

  private sendParamData() {


   
   
   
    //console.log("Localstrorage ===",localStorage.getItem("sendParamData")+mail_id.toString());
   // console.log("sendParamData===",sendParamData);
    //console.log("mail_id=",mail_id);
    let sendParamData = JSON.parse(localStorage.getItem("sendParamData"));
        
    this.directLineObj.postActivity({ 
      from: { id: self.userName },
      name: 'sendParamData',
      type: 'event',
      value: sendParamData
    })
      .subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
      });
      
  }

  private sendMailID() {
    let mail_id=self.digitalId.toString()+"@duracell.com";
    console.log("mail_id=",mail_id);
    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'mail_id',
      type: 'event',
      value: mail_id
    })
      .subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
      });
  }

  private sendBrowser_Language() {
    let Browser_Language=localStorage.getItem("Browser_Language");

    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'Browser_Language',
      type: 'event',
      value: Browser_Language
    })
      .subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
      });
  }
  
  private postLogoutActivity() {
    this.directLineObj.postActivity({
      from: { id: self.userName },
      name: 'UserLogoutActivity',
      type: 'event',
      value: ''
    })
      .subscribe((data) => {
        console.log("SUCCESS: User has logged out successfully!");
      });
  }


  private postMsgToBot(userMsg, shouldSendAsValue: boolean = true) {
    // TODO: Re-write the below code to incorporate delivery report in form data sending.
    if (!shouldSendAsValue) {
      // Form submission
      let formPayload = this.createFormPayload(userMsg);
      this.subscription.add(
        self.directLineObj.postActivity(formPayload)
          .subscribe(data => console.log('** Form posted: ', data))
      );
      return;
    }

    // First add the user message to the conversation list, and then POST it.
    let customMsgId: number;
    let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.User, userMsg, MsgType.TextOnly, new Date());
    customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();

    // POST the message.
    self.directLineObj.postActivity(this.createUserMsgPayload(userMsg, customMsgId))
      .subscribe(message => {
        console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);

        // Handle resending
        if (!message.includes('|')) {
          // Show resend icons for all pending messages.
          console.log("Retry sending the message!!");
          this.flagPendingMsgsForRetry();
        }
        return;
      },
        error => {
          console.log(`** postMsgToBot ERROR: ${JSON.stringify(error)}`);

          // Acknowledge the message receipt as NOT sent and show retry button.
          // self.postedErrorMsg(usrmsg);
          return;
        });

  }


  flagPendingMsgsForRetry() {
    for (let i in this.ackPendingIdxList) {
      let idx = this.ackPendingIdxList[i];
      this.conversation[idx].flagForResend();
    }
    console.log("** Flagged all pending messages for resend!!");
  }


  resendUserMsg(conversationIdx: number) {
    let resendingMsg: ChatMessageModel = this.conversation.splice(conversationIdx, 1)[0];
    console.log("** Resending the message: ", resendingMsg);

    // Also remove the entry from the ledger
    this._conversationCustomIdLedger.splice(conversationIdx, 1)[0];

    // Send the message
    this.postMsgToBot(resendingMsg.msgBody);
  }
  createUserMsgPayload_OtherParameter() {
    return {
      from: { id: self.userName },
      type: 'message',
      channelData: {
        'authObj': self.authObj,
        'geoLocation': self.geoLocationObj,
        'jwtToken': self.jwtToken,
        'mail_id': self.mail_id,
        'sendParamData': JSON.parse(localStorage.getItem("sendParamData")),
        'Browser_Language': self.Browser_Language,
        'username': this._appStateService.username
      }
    };
  }

  createUserMsgPayload(msg: string, customMsgId: number) {
    return {
      from: { id: self.userName },
      type: 'message',
      text: msg,
      channelData: {
        'authObj': self.authObj,
        'geoLocation': self.geoLocationObj,
        'jwtToken': self.jwtToken,
        'customUserId': customMsgId,
        'mail_id': self.mail_id,
        'sendParamData': JSON.parse(localStorage.getItem("sendParamData")),
        'Browser_Language': self.Browser_Language,
        'username': this._appStateService.username
      }
    };
  }


  createFormPayload(formData: any) {
    // let displayText = (formData instanceof String) ? formData : "Form submitted!";
   // let displayText = (typeof formData === "string") ? formData : "Form submitted!";
   // let displayText ="";
     // let displayText = (formData instanceof String) ? formData : "Form submitted!";


    console.log("formData=",formData);
   var lang =localStorage.getItem("lang");
    console.log("lang=",lang);
    let displayText =lang;
/*
     let displayText ="";
     if(lang.toString()=="en-US")
          displayText =(typeof formData === "string") ? formData : "Form submitted!";
     if(lang.toString()=="en")
          displayText =(typeof formData === "string") ? formData : "Form submitted!";
     if(lang.toString()=="es")
            displayText = (typeof formData === "string") ? formData : "Formulario enviado!";
     if(lang.toString()=="nl")
            displayText = (typeof formData === "string") ? formData : "Formulier ingeleverd!";
     if(lang.toString()=="pt")
            displayText = (typeof formData === "string") ? formData : "Formulário enviado!";
     if(lang.toString()=="zh-Hans")
            displayText = (typeof formData === "string") ? formData : "表格已提交！";
    */
 
     
     
    // let displayText ="";
    return {
      from: { id: self.userName },
      type: 'message',
      text: displayText,
      value: formData,
      channelData: {
        'authObj': self.authObj,
        'geoLocation': self.geoLocationObj,
        'jwtToken': self.jwtToken,
        'mail_id': self.mail_id,
        'sendParamData': JSON.parse(localStorage.getItem("sendParamData")),
        'Browser_Language': self.Browser_Language,
        'username': this._appStateService.username
      }
    };
  }


  submitFormCard(formData) {
    this.postMsgToBot(formData, false);
  }


  acknowledgeUserMessage(userMsg: any) {
    let conversationIdx: number = userMsg.channelData.customUserId;
    // if (conversationIdx >= this.conversation.length) {
    if (this.ackPendingIdxList.includes(conversationIdx)) {
      console.log("Acknowledgement received!!");
      this.conversation[conversationIdx].acknowledgeMsg();

      // Also remove it from the ackPendingIdxList
      let delIdx = this.ackPendingIdxList.indexOf(conversationIdx);
      this.ackPendingIdxList.splice(delIdx, 1);
    } else {
      console.log("** ATTENTION: Conversation-list is out of sync or message is already acknowledged!!", userMsg);
    }
  }


  // This controls the modal dialog of the input-container.
  showModalMsg(msgType: number, msgBody: string) {
    this._modalMsgBody = msgBody;
    this._shouldShowModalMsg = true;
    this._modalMsgType = msgType;
    this._isTxtDisabled = (msgType == 2);
  }


  hideModalMsg() {
    this._modalMsgBody = null;
    this._shouldShowModalMsg = false;
    this._modalMsgType = -1;
    this._isTxtDisabled = false;
  }


  showHideModalMsg(msgType: number, msgBody: string) {
    this.showModalMsg(msgType, msgBody);
    setTimeout(() => {
      this.hideModalMsg();
    }, 600);
  }


  public toggleBtnVisibility(card: NewTktCardModel) {
    card.isButtonsShown = !card.isButtonsShown;
  }


  public hideCardBtns(card: NewTktCardModel) {
    card.isButtonsShown = false;
  }


  public showCardBtns(event: Event, card: NewTktCardModel) {
    event.stopPropagation();
    card.isButtonsShown = true;
  }


  private addMsgToConversation(newMsg: ChatMessageModel): number {
    

    let msgCount = this.conversation.length;
    let customId: number;
    let lastMsg: ChatMessageModel;

    if (msgCount > 0) {
      lastMsg = this.conversation[msgCount - 1];
      // Check if last message avatar needs to be hidden.
      if (lastMsg.author == newMsg.author) {
        lastMsg.isAvatarShown = false;
      }
    }

    // Make last message react to the new message.
    if (this.reactPendingIdxList.length > 0 && newMsg.author == MsgAuthor.User) {
      for (let i in this.reactPendingIdxList) {
        let convIdx = this.reactPendingIdxList[i];
        console.log(`** ConvIdx: ${convIdx}`);

        if (typeof this.conversation[convIdx].msgBody.reactToNewMessage === 'function') {
          this.conversation[convIdx].msgBody.reactToNewMessage(newMsg);
        } else if (this.chkArrEleCanReact(this.conversation[convIdx].msgBody)) {
            
          for (let msgItem of this.conversation[convIdx].msgBody) {
            msgItem.reactToNewMessage(newMsg);
          }
        }
      }
      this.reactPendingIdxList = [];
    }

    // Handle pending acknowledgement messages.
    if (newMsg.isAckPending) {
      this.ackPendingIdxList.push(this.conversation.length);
    }

    // Add new message to conversation list & generate CustomID.
    customId = this.conversation.push(newMsg) - 1;

    // Add new message to react-pending list
    if (typeof newMsg.msgBody.reactToNewMessage === 'function' || this.chkArrEleCanReact(newMsg.msgBody)) {
      this.reactPendingIdxList.push(customId);
    }

    // Push CustomID to ledger
    if (newMsg.author == MsgAuthor.User) {
      this._conversationCustomIdLedger.push(customId);
    } else {
      // There is no use of customID for messages from bot.
      this._conversationCustomIdLedger.push(-1);
    }
    

    
    // Set focus on TextArea
    this.focusOnTA();

    if(document.getElementsByClassName("bubble-container")[0].innerHTML!=""){
      this.EmptyMsgID=setInterval(function(){ 
    
        if(document.getElementsByClassName("bubble-container")[0].innerHTML!=""){
        
          document.getElementsByClassName("bubble-container")[0].innerHTML="";
        
        }
      }, 1);
      
    }else{
      clearInterval(this.EmptyMsgID);
    }
    ///
    return customId;
  }


  private chkArrEleCanReact(msgBody: any) {
    const chkResult = Array.isArray(msgBody) && (msgBody.length > 0) && (typeof msgBody[0].reactToNewMessage === 'function');
    console.log('[chkArrEleCanReact] msgBody: ', msgBody);
    console.log('[chkArrEleCanReact] chkResult: ', chkResult);
    return chkResult;
  }


  private scrollToBottom() {
    clearTimeout(this._scrollTimer);
    this._scrollTimer = setTimeout(function () {
      let div2 = document.getElementsByClassName("bottom-rise-enabler-2")[0];
      if (div2) {
        div2.scrollTop = div2.scrollHeight;
      }
    }, 200);
  }


  private focusOnTA() {
    document.getElementById("textareaMsg").focus();
  }


  // NOTE: rawData is the "attachments" in the original response.
  private parseRawToStatusCards(rawData: any): Array<TktStatusCardModel> {
    // text contains '----'
    let dataList: Array<any> = rawData[0].content.body;
    let blockLength: number = 4;
    let itCount = (dataList.length - 1) / blockLength;
    let baseIdx: number;
    let newStatusCard: TktStatusCardModel, tktNumber: string, desc: string, status: number;
    let statusCards: Array<TktStatusCardModel> = [];

    for (let i = 0; i < itCount; i++) {
      baseIdx = (i * blockLength) + 1;

      tktNumber = dataList[baseIdx].text.split(':')[1].trim();
      desc = dataList[baseIdx + 1].text.split(':')[1].trim();
      status = dataList[baseIdx + 2].text.split(':')[1].trim();

      newStatusCard = new TktStatusCardModel(tktNumber, status, desc);
      statusCards.push(newStatusCard);
    }

    return statusCards;
  }


  private addInfoCardToConversation(rawData: any, msgTime: Date) {
    // Parse raw into info-card model
    let newInfoCard = new InfoCardModel(rawData);

    if (self.isCollectingInfoArticles) {
      // Add the new item to last item of conversation
      self.conversation[self.conversation.length - 1].msgBody.push(newInfoCard);
    } else {
      // Create a new entry in conversation
      let newMsg = new ChatMessageModel(MsgAuthor.Bot, [newInfoCard], MsgType.InfoCardCollection, msgTime);
      self.addMsgToConversation(newMsg);
      self.isCollectingInfoArticles = true; // Reset when next text message is received.
    }
  }


  private refreshToken() {
    console.log("** Refreshing DirectLine token!");
    self._directLine.refreshDirectLineToken(self.directLineToken);
  }


  private toggleCardGroupState(conversationIdx: number) {
    this.conversation[conversationIdx].isExpanded = !this.conversation[conversationIdx].isExpanded;
  }


  // Handle Calendar
  date: Date = new Date();

  settings = {
    bigBanner: false,
    timePicker: false,
    format: 'dd-MM-yyyy',
    defaultOpen: true,
    // closeOnSelect: true
  }

  public onDateSelect(dateInput: any) {
    if (self.sendCalDate) {
      self.directLineObj.postActivity({
        from: { id: self.userName },
        type: 'message',
        text: new String(self.date).slice(0, 15),
        channelData: { 'authObj': self.authObj, 'geoLocation': self.geoLocationObj, 'jwtToken': self.jwtToken }
      }).subscribe(function (message: any) {
        console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);
        return;
      }, function (error: any) {
        console.log(`** Error in posting: ${JSON.stringify(error)}`);
        return;
      });
      self.sendCalDate = false;
    }
    else {
      self.date = new Date();
    }
  }


  addNote() {
    self.msgToBot = "Add Note";
    self.sendMsgToBot();
  }


  public openIframe(url: string) {
    this._appStateService.urlIframe = url;
    this._appStateService.isIframeShown = true;
  }


  public openPopup(url: string) {
    window.open(url, 'popup', 'width=900,height=600');
    return false;
  }


  //Toggle Listen Mode
  // toggleListenMode() {
  //   (!this.isListening) ? this.listenUserSpeech() : this.stopListenUserSpeech();
  // }

  // textToSpeechClient(msg:any){

  //   let wordBoundaryList = [];
  //   let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.voiceSubscriptionDetails["subscriptionKey"], this.voiceSubscriptionDetails["serviceRegion"]);
  //   let player = new SpeechSDK.SpeakerAudioDestination();

  //   player.onAudioEnd = function (_) {
  //     console.log("playback finished");
  //     wordBoundaryList = [];
  //   };

  //   var audioConfig  = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

  //   speechConfig.speechRecognitionLanguage = this.voiceSubscriptionDetails["speechRecognitionLanguage"];

  //   let synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

  //           // The event synthesizing signals that a synthesized audio chunk is received.
  //       // You will receive one or more synthesizing events as a speech phrase is synthesized.
  //       // You can use this callback to streaming receive the synthesized audio.
  //       synthesizer.synthesizing = function (s, e) {
  //         console.log(e);
  //         console.log("(synthesizing) Reason: " + SpeechSDK.ResultReason[e.result.reason] +
  //                 "Audio chunk length: " + e.result.audioData.byteLength);
  //       };

  //       // The synthesis started event signals that the synthesis is started.
  //       synthesizer.synthesisStarted = function (s, e) {
  //         console.log(e);
  //         console.log("(synthesis started)")
  //       };

  //       // The event synthesis completed signals that the synthesis is completed.
  //       synthesizer.synthesisCompleted = function (s, e) {
  //         console.log(e);
  //         console.log("(synthesized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] +
  //                 " Audio length: " + e.result.audioData.byteLength);
  //         this.textToSpeechmsg='';
  //       };

  //       // The event signals that the service has stopped processing speech.
  //       // This can happen when an error is encountered.
  //       synthesizer.SynthesisCanceled = function (s, e) {
  //         const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(e.result);
  //         let str = "(cancel) Reason: " + SpeechSDK.CancellationReason[cancellationDetails.reason];
  //         if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
  //           str += ": " + e.result.errorDetails;
  //         }
  //         console.log("reason: "+e.result.reason);
            
  //       };

  //       // This event signals that word boundary is received. This indicates the audio boundary of each word.
  //       // The unit of e.audioOffset is tick (1 tick = 100 nanoseconds), divide by 10,000 to convert to milliseconds.
  //       synthesizer.wordBoundary = function (s, e) {
  //         window.console.log(e);
  //         console.log("(WordBoundary), Text: " + e.text + ", Audio offset: " + e.audioOffset / 10000 + "ms.");
  //         wordBoundaryList.push(e);
  //       };

  //       const complete_cb = function (result) {
  //         if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
  //           console.log("synthesis finished");
  //         } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
  //           console.log("synthesis failed. Error detail: " + result.errorDetails);
  //         }
  //         console.log("complete_cb: "+result);
  //         synthesizer.close();
  //         synthesizer = undefined;
  //       };

  //       const err_cb = function (err) {
  //         console.log(err);
  //         synthesizer.close();
  //         synthesizer = undefined;
  //       };

  //         synthesizer.speakTextAsync(msg,
  //           complete_cb,
  //           err_cb); 
        

  // }

  // listenUserSpeech() {
  //   let previousTextContent = (self.msgToBot) ? self.msgToBot : '';
  //   let statusDiv = "";
    
  //   let audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  //   let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.voiceSubscriptionDetails["subscriptionKey"], this.voiceSubscriptionDetails["serviceRegion"]);
    
  //   speechConfig.speechRecognitionLanguage = this.voiceSubscriptionDetails["speechRecognitionLanguage"];
  //   speechConfig.endpointId = this.voiceSubscriptionDetails["endpointId"];

  //   this.isListening = true;
  //   this.restartMicTimer();
  //   console.log("listening");

  //   self.reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
  //  // console.log(JSON.stringify(this.reco));

  //   //  The event recognizing signals that an intermediate recognition result is received.
  //   self.reco.recognizing = (s, e) => {
  //     this.restartMicTimer();
  //     console.log('recognizing text', e.result.text);
  //     this.msgToBot = previousTextContent + ' ' + e.result.text;
  //   };

  //   //  The event recognized signals that a final recognition result is received.
  //   self.reco.recognized = (s, e) => {
  //     this.restartMicTimer();
  //     console.log('recognized text', e.result.text);

  //     // Indicates that recognizable speech was not detected, and that recognition is done.
  //     if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
  //       var noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(e.result);
  //       statusDiv += "(recognized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] + " NoMatchReason: " + SpeechSDK.NoMatchReason[noMatchDetail.reason] + "\r\n";
  //       console.log(statusDiv);
  //     } else {
  //       statusDiv += "(recognized)  Reason: " + SpeechSDK.ResultReason[e.result.reason] + " Text: " + e.result.text + "\r\n";
  //       console.log(statusDiv);
  //       //previousTextContent += ' ' + e.result.text;
  //       this.msgToBot = e.result.text;
  //       this.sendMsgToBot()
  //       //this.textToSpeechClient(this.msgToBot);
  //     }
  //   };

  //   self.reco.canceled = function (s, e) {
  //     window.console.log(e);

  //     statusDiv += "(cancel) Reason: " + SpeechSDK.CancellationReason[e.reason];
  //     if (e.reason === SpeechSDK.CancellationReason.Error) {
  //       statusDiv += ": " + e.errorDetails;
  //       console.log(statusDiv);
  //     }
  //     statusDiv += "\r\n";
  //   };

  //   // Signals that a new session has started with the speech service
  //   self.reco.sessionStarted = function (s, e) {
  //     window.console.log(e);
  //     statusDiv += "(sessionStarted) SessionId: " + e.sessionId + "\r\n";
  //     console.log(statusDiv);
  //   };

  //   // Signals the end of a session with the speech service.
  //   self.reco.sessionStopped = function (s, e) {
  //     window.console.log(e);
  //     statusDiv += "(sessionStopped) SessionId: " + e.sessionId + "\r\n";
  //     this.isListening = false;
  //   };

  //   // Signals that the speech service has started to detect speech.
  //   self.reco.speechStartDetected = function (s, e) {
  //     window.console.log(e);
  //     statusDiv += "(speechStartDetected) SessionId: " + e.sessionId + "\r\n";
  //     console.log(statusDiv);
  //   };

  //   // Signals that the speech service has detected that speech has stopped.
  //   self.reco.speechEndDetected = function (s, e) {
  //     window.console.log(e);
  //     statusDiv += "(speechEndDetected) SessionId: " + e.sessionId + "\r\n";
  //     console.log(statusDiv);
  //   };

  //   // Starts recognition
  //   self.reco.startContinuousRecognitionAsync();
  // }


  // stopListenUserSpeech() {
  //   this.isListening = false;
  //   if (self.reco) {
  //     self.reco.stopContinuousRecognitionAsync(
  //       function () {
  //         self.reco.close();
  //         self.reco = undefined;
  //       },
  //       function (err) {
  //         self.reco.close();
  //         self.reco = undefined;
  //       });
  //   }
  // }


  // restartMicTimer(){
  //   clearTimeout(this._micTimer);
  //   this._micTimer = setTimeout(() =>  {
  //     this.stopListenUserSpeech();
  //   }, 4000);
  // }


  public sendStatusCardMsg(tktObj: TktStatusCardModel){
    const msg = `Yes, show status of ${tktObj.tktNumber}`;
    this.postMsgToBot(msg);
  }

  restartConversation(){
    console.log("conversation has been reset");
    // this.conversation = [];
    // this.reactPendingIdxList = [];
    self.postMsgToBot('RESTART');
  }


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _directLine: DirectLineService,
    private _sharedService: SharedService,
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
  ) {
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(this.constructor.name);
    // this.clickEventsubscription = this._sharedService.getClickEvent().subscribe(()=>{
    //   console.log("conversation array:", this.conversation);
    //   this.restartConversation();
    // })
  }


  ngOnInit() {
 debugger;
    self = this;
    let sub = this.route
      .queryParams
      .subscribe(params => {
        self.fetchParamsData(params);
      });


      

    // TODO: Pass the conversation_id alongwith the secret, to restore the interrupted conversation.
    // TODO: For token, pass streamUrl
    this.directLineObj = this._directLine.createDirectlineConnection(self.directLineToken);
    this._appStateService.dlObj = this.directLineObj;
    this._sharedService.userClickedfromLeftmenu = this.clickedfromLeftMenu;
    this.checkDirectlineConnection();


     
    
     
   
    this.subscribeMessages();
   
   // this.postMsgToBot("",false);
   
    console.log("Voice: ", this._appStateService.getVoiceSubscriptionDetails());
    
    
    this.voiceSubscriptionDetails = this._appStateService.getVoiceSubscriptionDetails();
    this.getOtherParameter();
    // Post login success
    
    if (this._appStateService.shouldShowGreeting) {
           this.postLoginSuccess();
            this._appStateService.shouldShowGreeting = false;
      }

    //get paramData
  this.sendParamData();
    //get mail id
   this.sendMailID();
    //get Browser_Language
   this.sendBrowser_Language();
   
   

    // let customMsgId: number
    // let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.Bot, "Thank you for contacting IVA. This virtual assistant provides response to FAQ's on IS (SBWS,O365 onboarding, MS Teams, Sharepoint Online, Exchange online, Wifi, VPN, proxy etc) and IRC service. How may I help you today?", MsgType.TextOnly, new Date());
    // customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();  

    // this.hideModalMsg();
    this.focusOnTA();

    // Set timer to refresh token after every 25mins.
    this._timerTokenRefresh = setInterval(() => {
      self.refreshToken();
    }, 1500000);
  }
 
  ngOnDestroy() {
    window.clearInterval(this._timerTokenRefresh);
    this.subscription.unsubscribe();
    this.postLogoutActivity();
  }

}
