import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly urlLoginDetails = 'login/details';
  private readonly urlLogoutDetails = 'logout';
  
  public azureAdAuthentication(data: any){
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log("environment.authAPIURL: ", environment.authAPIURL);
    // return this._http.post<any>(environment.authAPIURL, JSON.stringify(data));
    return this._http.post(environment.authAPIURL, JSON.stringify(data), options);
  }


  public initiateSSO() {
    console.log('Initiating SSO ...');
    return this._http.get('https://csd-auth-service-dev.azurewebsites.net/api/v1/authenticate/security/auth/sso');
  }


  public fetchAuthInfo(sessionToken: string) {
    const url = environment.apiGatewayBaseUrl + this.urlLoginDetails;
    const opt = {
      'headers': {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken
      },
    };

    console.log('[fetchAuthInfo] Querying login at: ', url);
    console.log('[fetchAuthInfo] Options: ', opt);
    return this._http.get(url, opt);
  }

  public logoutUser(){
    let sessionToken = localStorage.getItem('csd-x-auth-token');
    let url = environment.apiGatewayBaseUrl + this.urlLogoutDetails;

    const opt = {
      'headers': {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken
      }
    }
    return this._http.get(url,opt);
  }

  constructor(
    private _http: HttpClient,
  ) { }
}
