import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DirectLine, CardAction, IBotConnection, User, ConnectionStatus } from '../services/botframework-directlinejs';
//import { AdaptiveCard, Action, HostConfig, IValidationError, OpenUrlAction, SubmitAction } from "adaptivecards";
import { IAction, IAdaptiveCard, IOpenUrlAction, IShowCardAction, ISubmitAction } from 'adaptivecards/lib/schema';
import { Conversation, hostConfig } from './chat';
import { SharedService } from '../services/sharedService';
import { DirectLineService } from '../services/directLineService';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { RenderCards } from './RenderCards';
import { RenderMessages } from './RenderMessages';

let self: any;

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit {

  directLineObj: any;
  users: Conversation[] = [];
  msgToBot: string;
  isUserSent: number = 1;
  userName: string;
  authObj: any;
  geoLocationObj: any = { 'country': 'Netherlands', 'city': 'Amsterdam' };
  directLineSecret: string;
  msgHeader: string = '';
  imgSrc: any;
  headerSubText: string = '';
  jwtToken: string = '';
  autoFocus: boolean = true;
  noHeader: boolean = true;
  headerDiv: boolean = false;
  lastMsgPresent: boolean = false;
  lastMsgCount = 0;
  error: boolean = false;
  errorMsg: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _directLine: DirectLineService,
    private _sharedService: SharedService
  ) { }

  ngOnInit() {
    self = this;
    self.lastMsgPresent = false;
    let sub = this.route
      .queryParams
      .subscribe(params => {
        self.fetchParamsData(params);
      });

    this.directLineObj = this._directLine.createDirectlineConnection(self.directLineSecret);
    this._sharedService.userClickedfromLeftmenu = this.clickedfromLeftMenu;
    this.checkDirectlineConnection();
    this.subscribeMessages();
    setTimeout(() => {
      self._directLine.refreshDirectLineToken(self.directLineSecret);
    }, 1500000);
  }

  fetchParamsData(params: any) {
    let data = atob(params['data'] || '');
    if (data !== '') {
      self.authObj = JSON.parse(data);
      self.userName = self.authObj.bot_user.substring(0, self.authObj.bot_user.lastIndexOf("@"));
      self.directLineSecret = self.authObj.directlineSecrect;
      self.jwtToken = self.authObj.jwtToken;
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  subscribeMessages() {
    this.directLineObj.activity$
      .filter(function (activity: any) {
        return activity.type === 'message' && (activity.from.id === self.userName);
      })
      .subscribe(function (message: any) {
        console.log("message from user", message);
      });

    this.directLineObj.activity$
      .filter(function (activity: any) {
        return activity.type === 'message' && (activity.from.id !== self.userName);
      })
      .subscribe(function (message: any) {
        console.log("message", message);
        if (self.lastMsgPresent) {
          let msg = new Conversation(true, message.timestamp, '', message.from.name);
          self.users.push(msg);
          new RenderMessages().renderBotMessages(message, self.directLineObj, self.userName);
          if (message.channelData != undefined && message.channelData.endOfConversation) {
            self.showMsgHeader(message.channelData.endOfConversation);
          }
        }
        else if (!self.lastMsgPresent && self.lastMsgCount <= 0) {
          let msgText = "You are in the middle of your Conversation. Enter 'Cancel' to reset.";
          let msg = new Conversation(true, message.timestamp, msgText, message.from.name);
          self.users.push(msg);
          self.lastMsgCount++;
        }
        new RenderMessages().scrollToBottom();
      });
  }

  sendMsgToBot() {
    if (this.msgToBot != undefined && this.msgToBot != '') {
      self.checkDirectlineConnection();
      this.msgToBot = '';
    }
  }

  clickedfromLeftMenu(msg: string) {
    self.msgToBot = msg;
    self.sendMsgToBot();
    self.showMsgHeader(false, msg);
  }

  private checkDirectlineConnection() {
    self.directLineObj.connectionStatus$
      .subscribe(connectionStatus => {
        if (connectionStatus === 2) {
          self.postMsgToBot();
        }
        else if (connectionStatus === 3 || connectionStatus === 4 || connectionStatus === 5) {
          if (connectionStatus === 3) self.errorMsg = "Bot Token Expired. Login Again to start chat.";
          else if (connectionStatus === 4) self.errorMsg = " Failed to establish connection with Bot.Login Again to start chat.";
          else if (connectionStatus === 5) self.errorMsg = "Coversation Session is Ended. Login Again to start chat.";
          self.error = true;
        }
      });
  }

  private postMsgToBot() {
    var usrmsg = self.msgToBot;
    if (self.msgToBot) {
      self.directLineObj.postActivity({
        from: { id: self.userName },
        type: 'message',
        text: this.msgToBot,
        channelData: { 'authObj': self.authObj, 'geoLocation': self.geoLocationObj, 'jwtToken': self.jwtToken }
      }).subscribe(function (message: any) {
        self.postedSuccessMsg(usrmsg);
        return;
      }, function (error: any) {
        self.postedErrorMsg(usrmsg);
        return;
      });
    }
  }

  postedSuccessMsg(usrmsg: string) {
    console.log("Success Posted activity");
    self.lastMsgPresent = true;
    let msg = new Conversation(false, new Date(), usrmsg, self.userName);
    self.users.push(msg);
    new RenderMessages().scrollToBottom();
    self.isUserSent = new RenderMessages().updateHTML(self.isUserSent, "retry");
    self.autoFocus = true;
  }

  postedErrorMsg(usrmsg: string) {
    console.log("Error posting activity");
    let msg = new Conversation(false, '', usrmsg, self.userName);
    self.users.push(msg);
    new RenderMessages().scrollToBottom();
    self.isUserSent = new RenderMessages().updateHTML(self.isUserSent, "msgSent")
  }

  sendRetry() {
    var userObj = this.users[this.users.length - 1];
    var rMsg = userObj.text;
    this.directLineObj.postActivity({
      from: { id: self.userName },
      type: 'message',
      text: rMsg,
      channelData: { 'authObj': self.authObj, 'geoLocation': self.geoLocationObj, 'jwtToken': self.jwtToken }
    }).subscribe(function (message: any) {
      self.isUserSent = new RenderMessages().updateHTML(self.isUserSent, "retry")
      self.autoFocus = true;
      return console.log("Posted activity", message);
    }, function (error: any) {
      self.isUserSent = new RenderMessages().updateHTML(self.isUserSent, "msgSent")
      return console.log("Error posting activity", error);
    });
  }

  showMsgHeader(msgDiv: boolean, msgHeader?: any) {
    if (!msgDiv) {
      self.noHeader = false;
      self.headerDiv = true;
      switch (msgHeader) {
        case 'Weather': self.msgHeader = msgHeader; self.imgSrc = 'assets/Icons/Weather.svg'; self.headerSubText = 'Helps you to know weather.'; break;
        case 'News': self.msgHeader = msgHeader; self.imgSrc = 'assets/Icons/News.svg'; self.headerSubText = 'Helps you to know news.'; break;
        case 'Workspace': self.msgHeader = msgHeader; self.imgSrc = 'assets/Icons/Workspace.svg'; self.headerSubText = 'Helps you to know your workspace.'; break;
        case 'Ticket Status': self.msgHeader = msgHeader; self.imgSrc = 'assets/Icons/Ticket.svg'; self.headerSubText = 'Helps you to raise, update and cancel ticket.'; break;
        case 'Ticket Update': self.msgHeader = msgHeader; self.imgSrc = 'assets/Icons/Update_Ticket.svg'; self.headerSubText = 'Helps you to raise, update and cancel ticket.'; break;
        default: self.msgHeader = ''; self.imgSrc = ''; self.headerSubText = ''; break;
      }
    }
    else {
      self.noHeader = true;
      self.headerDiv = false;
    }
  }

}








